/* STYLES FOR HEADERS */
.mail-icon {
  transition: transform 0.6s ease;
}

.mail-icon:hover {
  transform: rotate(30deg);
}

/* STYLES FOR NAVBAR */
.navigate {
  transition: background-color 2s ease;
  transition: color 0.7s ease;
}
.navigate:hover {
  background-color: white;
  color: black;
}

.navigatee {
  transition: background-color 2s ease;
  transition: color 0.7s ease;
}
.navigatee:hover {
  background-color: black;
  color: white;
}

.navbar-menu span {
  height: 6.5rem;
}

.hoverable-span {
  height: 7rem;
}

.underlines {
  transition: text-decoration 0.6s ease;
}
.underlines:hover {
  text-decoration: underline;
}

/* CARD STYLES */

.card-image {
  z-index: 3;
  transition: transform 0.6s ease;
}
.card-image:hover {
  transform: scale(0.9);
}
/* @media screen and (max-width: 1025px) {
  .footer {
    justify-items: center !important;
    padding-top: 2rem;
  }
} */
