input::placeholder {
  color: white; /* Replace 'red' with your desired color */
}

.blink {
  animation: blink 1s infinite;
}

@keyframes blink {
  0% {
    scale: 1;
  }
  100% {
    scale: 0.8;
  }
}

.img-box {
  max-width: 504px;
  width: 100%;
  height: min-content;
  overflow: hidden;
  height: 720px;
  border-radius: 300px;
  aspect-ratio: 0.7 / 1;
}
.img-box img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.img-box .img-box-inner {
  width: 100%;
  height: 100%;
  position: relative;
  transform: scale(1.2);
}

/* .slider-Testimonials {
  position: relative;
  width: 10%;
  display: grid;
  place-items: center;
  padding-top: 2%;
  padding-bottom: 2%;
}

.slide-track-Testimonials {
  display: flex;
  width: calc(200px * 10);
  animation: scroll 25s infinite linear;
  margin: 0;
}

@keyframes scroll {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-100%);
  }
} */

.img2-box {
  max-width: 350px;
  width: 100%;
  height: min-content;
  overflow: hidden;
  height: 580px;
  border-radius: 300px;
  aspect-ratio: 0.7 / 1;
}
.img2-box img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.img2-box .img2-box-inner {
  width: 100%;
  height: 100%;
  position: relative;
}

@media screen and (max-width: 517px) {
  .mob-logo {
    max-width: 350px;
    width: 100%;
    height: min-content;
    overflow: hidden;
    height: 580px;
    border-radius: 300px;
    aspect-ratio: 0.7 / 1;
  }
  .mob-logo img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: fill;
  }
  .mob-logo .img2-box-inner {
    width: 100%;
    height: 100%;
    position: relative;
  }
}

@media screen and (max-width: 362px) {
  .mob-logo {
    max-width: 345px;
    width: 100%;
    height: min-content;
    overflow: hidden;
    height: 580px;
    border-radius: 300px;
    aspect-ratio: 0.7 / 1;
  }
  .mob-logo img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: fill;
  }
  .mob-logo .img2-box-inner {
    width: 100%;
    height: 100%;
    position: relative;
  }
}

/* CAROUSEL CSS */
.carousel {
  display: flex;
  overflow: hidden;
  white-space: nowrap;
}

.carousel-track {
  display: flex;
}

.carousel-track.forward {
  animation: scrollForward 10s linear infinite;
}

.carousel-track.reverse {
  animation: scrollReverse 10s linear infinite;
}

.carousel-item {
  flex: 0 0 auto;
  width: 30%;
  height: 70%;
}

.carousel-item img {
  width: 100%;
  height: auto;
}

@keyframes scrollForward {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

@keyframes scrollReverse {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}

.full-image-style {
  rotate: none;
  scale: none;
  transform: translate3d(0px, 0px, 0px) scale(1.2372, 1.2372);
}
.full-image-sec .full-image-style {
  padding-left: 20rem;
  padding-right: 20rem;
}
.full-image-sec .full-image-style .full-image-box {
  border-radius: 20rem;
}

@media screen and (max-width: 432px) {
  .contact-details {
    flex-direction: column;
    padding-bottom: 1rem;
  }
  .content {
    font-size: 1.5rem;
  }
}

@media screen and (max-width: 500px) {
  .carousel-mob {
    height: 500px;
    width: 500px;
  }
}
@media screen and (max-width: 544px) {
  .title {
    font-size: 4.8rem;
    letter-spacing: normal;
    line-height: 3rem;
  }
}
@media screen and (max-width: 363px) {
  .title {
    font-size: 3.5rem;
    letter-spacing: normal;
    line-height: 2rem;
  }
}

@media screen and (max-width: 545px) {
  .service-content-title {
    margin-top: 2rem;
  }
}
@media (min-width: 300px) {
  .logo {
    width: 90%;
    font-size: 4.7rem;
    min-height: 0rem;
    letter-spacing: normal;
  }
}
@media (min-width: 500px) {
  .logo {
    width: 90%;
    font-size: 7.4rem;
    min-height: 0rem;
    letter-spacing: normal;
  }
}
@media (min-width: 800px) {
  .logo {
    width: 90%;
    font-size: 9.9rem;
    min-height: 0rem;
    letter-spacing: normal;
  }
}
@media (min-width: 1200px) {
  .logo {
    /* width: 90%; */
    font-size: 15rem;
    /* min-height: 0rem;
    letter-spacing: normal; */
    /* padding-left: 1rem;
    padding-right: 1rem; */
  }
}
@media (min-width: 2000px) {
  .logo {
    width: 90%;
    font-size: 18rem;
    min-height: 0rem;
    letter-spacing: normal;
    padding-bottom: 5rem !important;
  }
}
@media screen and (max-width: 290px) {
  .logo {
    width: 90%;
    font-size: 4rem;
    min-height: 0rem;
    letter-spacing: normal;
  }
}

/* @media screen and (max-width: 650px) {
  .logo {
    width: 90%;
    font-size: 7rem;
    min-height: 0rem;
    letter-spacing: normal;
  }
}
@media screen and (max-width: 800px) {
  .logo {
    width: 90%;
    font-size: 10rem;
    min-height: 0rem;
    letter-spacing: normal;
  }
} */

@media screen and (max-width: 1000px) {
  .footer-content {
    flex-direction: column;
    width: 100% !important;
  }
}

@media (min-width: 1001px) {
  .footer-content {
    flex-direction: row;
    width: 50% !important;
  }
}

@media screen and (max-width: 815px) {
  .footer {
    flex-direction: column;
  }
}
@media screen and (max-width: 1024px) and (max-height: 600px) {
  .logo {
    font-size: 12rem;
  }
}

@media screen and (max-width: 540px) {
  .social {
    align-items: start;
    padding-left: 0%;
    padding-right: 0%;
  }
}
@media screen and (max-width: 540px) {
  .rights {
    text-align: center;
  }
}
@media screen and (max-width: 1055px) {
  .card {
    gap: 1rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}
@media screen and (max-width: 700px) {
  .welcome {
    font-size: 0.9rem;
  }
}
@media screen and (max-width: 500px) {
  .welcome {
    font-size: 0.7rem;
  }
}
/* @media screen and (max-width: 1500px) {
  .title {
    height: 45vh;
    font-size: 7rem;
    line-height: 1.5rem;
  }
}

@media screen and (max-width: 1500px) {
  .welcome {
    padding-left: 3rem;
    padding-right: 3rem;
  }
}
@media screen and (max-width: 1500px) {
  .name {
    line-height: 5rem;
  }
} */
/* @media screen and (max-width: 1500px) {
} */

@media screen and (max-width: 405px) {
  .buton {
    font-size: 1.1rem;
    /* width: 20rem; */
    padding: 0.5rem;
  }
}
@media screen and (max-width: 900px) {
  .prices {
    width: 100%;
  }
}
/* @media (min-width: 100px) {
  .read {
    font-size: 20rem !important;
  }
} */
